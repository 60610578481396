import { capitalize } from '../../../../format';

// import { AlinityMResults } from '../../../types/data';

type Results = Record<string, string | null>;

/**
 * Stringifies results so they can be added to comments or downloadable Excel files.
 *
 * @param {Object} results - The results for a sample.
 * @returns {string} The stringified version of the results.
 */
function stringifyResults(results: Results): string {
	const resultsList = [];

	for (const [key, value] of Object.entries(results)) {
		let formattedKey;
		if (key === 'CT') {
			formattedKey = 'Chlamydia trachomatis';
		} else if (key === 'NG') {
			formattedKey = 'Neisseria gonorrhoeae';
		} else if (key === 'TV') {
			formattedKey = 'Trichomonas vaginalis';
		} else if (key === 'MG') {
			formattedKey = 'Mycoplasma genitalium';
		} else {
			throw new Error('Unknown STI results property');
		}

		const formattedValue = value
			? capitalize((value as string).replace('_', ' '))
			: 'null';
		resultsList.push(`${formattedKey}: ${formattedValue}`);
	}

	return resultsList.join(', ');
}

export default { stringifyResults };
