import FIT from './fit.json';
import STI from './sti.json';
import assays from './assays.json';
import RESP4PLEX from './resp4plex.json';
import BLADDERCARE from './bladdercare.json';

import { Option } from '../../common-types';
import { getAllRequisitionFormOptions } from './requisition-forms';

export const getAssayTests = (): Option[] => {
	return Object.values(getAllRequisitionFormOptions('tests', 'array'));
};

export const getSpecimenTypes = (): Option[] => {
	const values = (
		Object.values(
			getAllRequisitionFormOptions('specimen_types', 'array')
		) as Option[]
	).filter((option: Option) => !(option.value === 'other'));

	values.sort((a, b) => {
		if (a.value < b.value) return -1;
		if (a.value > b.value) return 1;
		return 0;
	});

	return [...values, { label: 'Other', value: 'other' }];
};

export const generateLabelForOrderTests = (orderTests: string[]) => {
	if (!orderTests) return null;

	const assayTests = getAssayTests();

	return orderTests
		.map(
			(test: string) =>
				assayTests.find(({ value }: Option) => test === value)?.label
		)
		.join(', ');
};

/**
 *
 * @param values: An array of option values.
 * @param options: An array of the options (with label and value).
 * @returns A string of the labels.
 */
export const generateStringFromArrayOptionValues = (
	values: string[],
	options: Option[]
) => {
	if (!values) return null;

	return values
		.map(
			(option: string) =>
				options.find(({ value }) => option === value)?.label
		)
		.join(', ');
};

export const ASSAYS: { [key: string]: any } = {
	assays: assays.assays,
	sti: STI,
	fit: FIT,
	resp4plex: RESP4PLEX,
	bladdercare: BLADDERCARE,
};

type Results = {
	[key: string]: string | null;
};

/**
 * Compare two dictionaries, find the keys where the values differ between the two dictionaries,
 * and return two new dictionaries containing only the differing key-value pairs from the
 * original dictionaries.
 *
 * @param {Object} dictA - The first dictionary to compare.
 * @param {Object} dictB - The second dictionary to compare.
 * @returns {Array} An array containing two dictionaries with the differing key-value pairs.
 */
export const getResultDifferences = (
	dictA: Results,
	dictB: Results
): [Results, Results] => {
	const differingKeys = new Set();

	for (const [key, value] of Object.entries(dictA)) {
		if (value !== dictB[key]) {
			differingKeys.add(key);
		}
	}

	const oldResults: Results = {};
	const newResults: Results = {};

	for (const key of differingKeys) {
		oldResults[key as string] = dictA[key as string];
		newResults[key as string] = dictB[key as string];
	}

	return [oldResults, newResults];
};
