import v1_0 from './v1-0/v1-0.json';
import shared from './shared.json';
import utils from './shared';

export const RESP4PLEX_v1 = v1_0;
export const RESP4PLEX_shared = shared;
export const RESP4PLEX_VERSIONS = [
	{
		label: '1.0',
		value: '1.0',
	},
];

export const RESP4PLEX: { [key: string]: any } = {
	'1.0': RESP4PLEX_v1,
	shared: RESP4PLEX_shared,
};

export const RESP4PLEX_UTILS = utils;
