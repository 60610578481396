import v1_0 from './v1-0/v1-0.json';
import shared from './shared.json';
import utils from './shared';

export const STI_v1_0 = v1_0;
export const STI_shared = shared;
export const STI_VERSIONS = [
	{
		label: '1.0',
		value: '1.0',
	},
];

export const STI: { [key: string]: any } = {
	'1.0': STI_v1_0,
	shared: STI_shared,
};

export const STI_UTILS = utils;
