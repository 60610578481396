import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	Modal,
	Button,
	useAxios,
	FormGroup,
	LoadingBox,
	FormFooter,
	TextButton,
	GenericField,
	TextareaField,
	DescriptionItem,
	DescriptionList,
} from '@pangea-lis-apps/ui';

import {
	formatDate,
	ClinicData,
	NonClinicData,
	ReportComment,
} from '@pangea-lis-apps/utils';

interface ReportCommentsProps {
	data: ClinicData | NonClinicData;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function ReportComments(props: ReportCommentsProps) {
	const { user } = useAuth0();

	const [comment, setComment] = useState<ReportComment | null>(null);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [removeModalVisible, setRemoveModalVisible] = useState(false);

	return (
		<Fragment>
			<EditCommentModal
				data={props.data}
				reportComment={comment}
				setRefresh={props.setRefresh}
				visible={editModalVisible}
				setVisible={setEditModalVisible}
			/>
			<RemoveCommentModal
				data={props.data}
				reportComment={comment}
				setRefresh={props.setRefresh}
				visible={removeModalVisible}
				setVisible={setRemoveModalVisible}
			/>
			<DescriptionItem term="Comments" customColSpan="sm:col-span-3">
				{user ? (
					props.data.sample.report.comments.length ? (
						<ul className="divide-y divide-gray-200 first:pt-0">
							{props.data.sample.report.comments.map(
								(comment) => (
									<li
										key={comment.id}
										className="py-4 first:pt-0 last:pb-0"
									>
										<div className="mb-2 flex items-start justify-between">
											<div className="space-x-2">
												<span className="font-medium text-sm text-gray-600">
													{comment.metadata.created_by
														? comment.metadata.created_by.first_name.concat(
																' ',
																comment.metadata
																	.created_by
																	.last_name
														  )
														: 'System'}
												</span>
												<span className="text-sm text-gray-500">
													{formatDate(
														comment.metadata
															.date_modified
															.$date,
														true
													)}
												</span>
												{comment.edited && (
													<span className="italic text-sm text-gray-400">
														Edited
													</span>
												)}
											</div>
											<div className="flex items-center justify-end space-x-2">
												{(comment.metadata
													.created_by === null ||
													comment.metadata.created_by
														.id === user.sub) && (
													<TextButton
														text="Edit"
														color="gray"
														type="button"
														onClick={() => {
															setComment(comment);
															setEditModalVisible(
																true
															);
														}}
													/>
												)}
												{comment.metadata.created_by &&
													comment.metadata.created_by
														.id === user.sub && (
														<TextButton
															color="gray"
															text="Remove"
															type="button"
															onClick={() => {
																setComment(
																	comment
																);
																setRemoveModalVisible(
																	true
																);
															}}
														/>
													)}
											</div>
										</div>
										<p className="inline text-sm">
											{comment.value}
										</p>
									</li>
								)
							)}
						</ul>
					) : (
						<p className="text-sm">-</p>
					)
				) : (
					<LoadingBox />
				)}
			</DescriptionItem>
		</Fragment>
	);
}

export default ReportComments;

interface ModalProps {
	visible: boolean;
	data: ClinicData | NonClinicData;
	reportComment: ReportComment | null;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	setVisible: Dispatch<SetStateAction<boolean>>;
}

function EditCommentModal({
	data,
	visible,
	setRefresh,
	setVisible,
	reportComment,
}: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const [comment, setComment] = useState('');
	const [disabled, setDisabled] = useState(false);

	const verified = data.sample.status === 'verified';

	useEffect(() => {
		if (reportComment) setComment(reportComment.value);
	}, [reportComment]);

	const handleSubmit = async () => {
		if (disabled || !axios || !reportComment) return;
		else if (comment === reportComment.value) {
			toast.error('No values were changed!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/cls/data/${data._id.$oid}/sample/report/comment`, {
				new_comment: comment,
				comment_id: reportComment.id,
				old_comment: reportComment.value,
			});

			toast.dismiss(toastId);
			setVisible(false);
			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setComment(target.value);
	};

	return (
		<Modal
			visible={visible}
			title="Edit comment"
			onClose={() => setVisible(false)}
		>
			{!reportComment ? (
				<LoadingBox />
			) : (
				<Fragment>
					{verified && (
						<Alert
							type="warning"
							className="mb-4"
							heading="New report will be generated"
							description="The report has already been verified. A new report will be generated with the provided comment. Any line breaks will be removed."
						/>
					)}
					<Form>
						<FormGroup>
							<div className="sm:col-span-6">
								<TextareaField
									required
									name="comment"
									label="Comment"
									// maxLength={220}
									value={comment}
									handleInputChange={handleChange}
									placeholder="e.g., Fails QC and waiting for rerun."
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								text="Update"
								type="button"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
								onClick={handleSubmit}
							/>
						</FormFooter>
					</Form>
				</Fragment>
			)}
		</Modal>
	);
}

function RemoveCommentModal({
	data,
	visible,
	setRefresh,
	setVisible,
	reportComment,
}: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const [confirm, setConfirm] = useState('');
	const [disabled, setDisabled] = useState(false);

	const verified = data.sample.status === 'verified';

	const handleSubmit = async () => {
		if (disabled || !axios || !reportComment) return;
		else if (confirm !== 'Yes') {
			toast.error('Please type "Yes" to confirm.');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).delete(
				`/api/cls/data/${data._id.$oid}/sample/report/comment/${reportComment.id}`
			);

			toast.dismiss(toastId);
			setVisible(false);
			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setConfirm(target.value);
	};

	return (
		<Modal
			visible={visible}
			title="Remove comment"
			customWidth="max-w-md"
			onClose={() => setVisible(false)}
		>
			{!reportComment ? (
				<LoadingBox />
			) : (
				<div className="space-y-4">
					{verified && (
						<Alert
							type="warning"
							heading="New report will be generated"
							description="The report has already been verified. A new report will be generated with the comment removed."
						/>
					)}
					<DescriptionList>
						<DescriptionItem
							term="Current comment"
							customColSpan="sm:col-span-3"
							details={reportComment.value}
						/>
					</DescriptionList>
					<Form>
						<FormGroup>
							<div className="sm:col-span-6">
								<GenericField
									required
									type="text"
									name="confirm"
									label={
										<span>
											Enter{' '}
											<span className="italic">Yes</span>{' '}
											to confirm removal.
										</span>
									}
									value={confirm}
									placeholder="Yes"
									handleInputChange={handleChange}
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								text="Update"
								type="button"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
								onClick={handleSubmit}
							/>
						</FormFooter>
					</Form>
				</div>
			)}
		</Modal>
	);
}
