import { capitalize } from '../../../../format';

// import { AlinityMResults } from '../../../types/data';

type Results = Record<string, string | null>;

/**
 * Stringifies results so they can be added to comments or downloadable Excel files.
 *
 * @param {Object} results - The results for a sample.
 * @returns {string} The stringified version of the results.
 */
function stringifyResults(results: Results) {
	const resultsList = [];

	for (const [key, value] of Object.entries(results)) {
		let formattedKey;
		if (key === 'FLUA_4PEUA') {
			formattedKey = 'Influenza A Virus';
		} else if (key === 'FLUB_4PEUA') {
			formattedKey = 'Influenza B Virus';
		} else if (key === 'RSV_4PEUA') {
			formattedKey = 'Respiratory Syncytial Virus';
		} else if (key === 'COV2_4PEUA') {
			formattedKey = 'SARS-CoV-2';
		} else {
			throw new Error('Unknown Resp4Plex results property');
		}

		const formattedValue = value
			? capitalize((value as string).replace('_', ' '))
			: 'null';
		resultsList.push(`${formattedKey}: ${formattedValue}`);
	}

	return resultsList.join(', ');
}

export default { stringifyResults };
