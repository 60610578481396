import {
	Option,
	ClinicData,
	NonClinicData,
	AlinityMResults,
	getLabelFromValue,
	BladderCAREResults,
	FITResults,
	AlinityMResultStatus,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

const results = Object.values(
	getAllRequisitionFormOptions('result')
) as Option[];

export interface SampleResultsProps {
	data: ClinicData | NonClinicData;
	alinityMAllOrOrdered?: 'all' | 'ordered';
	valuesToHighlight?: AlinityMResultStatus[];
}

export function SampleResults({
	data,
	valuesToHighlight,
	alinityMAllOrOrdered,
}: SampleResultsProps) {
	if (data.type === 'clinic')
		return (
			<ClinicSampleResults
				data={data}
				valuesToHighlight={valuesToHighlight}
				alinityMAllOrOrdered={alinityMAllOrOrdered}
			/>
		);

	return (
		<NonClinicSampleResults
			data={data}
			valuesToHighlight={valuesToHighlight}
			alinityMAllOrOrdered={alinityMAllOrOrdered}
		/>
	);
}

export function ClinicSampleResults(props: SampleResultsProps) {
	const assay = props.data.sample.assay;

	if (assay === 'sti' || assay === 'resp4plex') {
		return (
			<AlinityMSampleResults
				data={
					props.alinityMAllOrOrdered === 'all'
						? (props.data.sample.results as AlinityMResults).all
						: (props.data.sample.results as AlinityMResults).ordered
				}
				valuesToHighlight={[null, 'Detected', 'Invalid']}
			/>
		);
	} else if (assay === 'bladdercare') {
		return (
			<BladderCARESampleResults
				data={props.data.sample.results as BladderCAREResults}
			/>
		);
	} else if (assay === 'fit') {
		return (
			<FITSampleResults data={props.data.sample.results as FITResults} />
		);
	}

	return null;
}

export function NonClinicSampleResults(props: SampleResultsProps) {
	const assay = props.data.sample.assay;

	if (assay === 'sti' || assay === 'resp4plex') {
		return (
			<AlinityMSampleResults
				data={
					props.alinityMAllOrOrdered === 'all'
						? (props.data.sample.results as AlinityMResults).all
						: (props.data.sample.results as AlinityMResults).ordered
				}
				valuesToHighlight={[null, 'Detected', 'Invalid']}
			/>
		);
	} else if (assay === 'bladdercare') {
		return (
			<BladderCARESampleResults
				data={props.data.sample.results as BladderCAREResults}
			/>
		);
	} else if (assay === 'fit') {
		return (
			<FITSampleResults data={props.data.sample.results as FITResults} />
		);
	}

	return null;
}

interface AlinityMSampleResultsProps {
	valuesToHighlight: AlinityMResultStatus[];
	data: { [key: string]: AlinityMResultStatus } | null;
}

export function AlinityMSampleResults(props: AlinityMSampleResultsProps) {
	if (props.data)
		return (
			<ul>
				{Object.entries(props.data).map((test, index) => {
					const [key, value] = test;

					return (
						<li
							key={index}
							className={
								props.valuesToHighlight.includes(value)
									? 'font-semibold text-red-600'
									: ''
							}
						>
							{key.concat(
								': ',
								value === null
									? 'Null'
									: value
									? value.replace(/_/g, ' ')
									: '-'
							)}
						</li>
					);
				})}
			</ul>
		);

	return <span>Not yet available</span>;
}

interface BladderCARESampleResultsProps {
	data: BladderCAREResults;
}

function BladderCARESampleResults(props: BladderCARESampleResultsProps) {
	const { bci, result } = props.data as BladderCAREResults;

	if (bci && result) {
		return (
			<ul>
				<li>BCI: {bci === null ? 'Null' : bci}</li>
				<li>Result: {getLabelFromValue(result, results)}</li>
			</ul>
		);
	}

	return <span>Not yet available</span>;
}

interface FITSampleResultsProps {
	data: FITResults;
}

function FITSampleResults(props: FITSampleResultsProps) {
	const { result } = props.data as FITResults;

	if (result) {
		return (
			<ul>
				<li>{result.charAt(0).toUpperCase() + result.substring(1)}</li>
			</ul>
		);
	}

	return <span>Not yet available</span>;
}

export default SampleResults;
