/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment } from 'react';

import {
	ContentSection,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	STI,
	FORMS,
	ASSAYS,
	capitalize,
	formatDate,
	FieldProps,
	ICD_10_CODES,
	requiredStyles,
	REQUISITION_FORM,
	getSpecimenTypes,
	getLabelFromValue,
	generateLabelForOrderTests,
	MiscAndAttachmentsFieldProps,
} from '@pangea-lis-apps/utils';

import { Attachments } from '../../shared/attachments';

const STI_V1_0 = STI['1.0'];

const ProviderInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Provider Info"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="First Name"
						termStyles={requiredStyles(
							data.requisition_form.provider_first_name
						)}
						details={data.requisition_form.provider_first_name}
					/>
					<DescriptionItem
						term="Last Name"
						termStyles={requiredStyles(
							data.requisition_form.provider_last_name
						)}
						details={data.requisition_form.provider_last_name}
					/>
					<DescriptionItem
						term="National Provider Identifier (NPI)"
						termStyles={requiredStyles(
							data.requisition_form
								.provider_national_provider_identifier
						)}
						details={
							data.requisition_form
								.provider_national_provider_identifier
						}
					/>
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection heading="Provider Info" headingAction={headingAction}>
			<DescriptionList>
				<DescriptionItem
					term="Name"
					details={data.customer.first_name.concat(
						' ',
						data.customer.last_name
					)}
				/>
			</DescriptionList>
		</ContentSection>
	);
};

const OrganizationInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Organization Info"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="Name"
						details={data.requisition_form.organization_name}
						termStyles={requiredStyles(
							data.requisition_form.organization_name
						)}
					/>
					<DescriptionItem
						term="National Provider Identifier (NPI)"
						termStyles={requiredStyles(
							data.requisition_form
								.organization_national_provider_identifier!
						)}
						details={
							data.requisition_form
								.organization_national_provider_identifier
						}
					/>
					<DescriptionItem
						term="Phone Number"
						termStyles={requiredStyles(
							data.requisition_form.organization_phone_number
						)}
						details={
							data.requisition_form.organization_phone_number
						}
					/>
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.organization_address_address
						)}
						term="Address"
						details={
							data.requisition_form.organization_address_address
						}
					/>
					<DescriptionItem
						term="Address Line 2"
						details={
							data.requisition_form.organization_address_address2
						}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="City"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_city
						)}
						details={
							data.requisition_form.organization_address_city
						}
					/>
					<DescriptionItem
						term="State"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_state
						)}
						details={getLabelFromValue(
							data.requisition_form.organization_address_state,
							FORMS.states
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="ZIP Code"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_zip_code
						)}
						details={
							data.requisition_form.organization_address_zip_code
						}
					/>
					<DescriptionItem
						term="Country"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_country
						)}
						details={getLabelFromValue(
							data.requisition_form.organization_address_country,
							FORMS.countries
						)}
					/>
					<div className="sm:col-span-1"></div>
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection
			heading="Organization Info"
			headingAction={headingAction}
		>
			<DescriptionList>
				<DescriptionItem term="Name" details={data.organization.name} />
				<DescriptionItem
					term="Phone Number"
					details={data.organization.phone_number}
				/>
				<DescriptionItem term="Address">
					<p>
						{data.organization.address}
						<br />
						{data.organization.city}, {data.organization.state}{' '}
						{data.organization.zip_code} {data.organization.country}
					</p>
				</DescriptionItem>
			</DescriptionList>
		</ContentSection>
	);
};

const OrderAndSpecimenInformation = ({
	data,
	hide,
	headingAction,
}: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Order and specimen information"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="Assay"
						details={getLabelFromValue(
							data.requisition_form.sample_assay,
							ASSAYS['assays']
						)}
					/>
					<DescriptionItem
						term="Specimen Type"
						termStyles={requiredStyles(
							data.requisition_form.sample_specimen_type
						)}
						details={getLabelFromValue(
							data.requisition_form.sample_specimen_type,
							getSpecimenTypes()
						)}
					/>
					{!(hide && hide.includes('ICD-10 Code(s)')) && (
						<Fragment>
							<div className="sm:col-span-1"></div>
							<DescriptionItem
								term="ICD-10 Code(s)"
								termStyles={requiredStyles(
									data.requisition_form.order_icd_10_codes
								)}
							>
								{data.requisition_form.order_icd_10_codes
									.length ? (
									<ul className="list-disc list-inside">
										{data.requisition_form.order_icd_10_codes.map(
											(code: string, index: number) => (
												<li key={index}>
													{getLabelFromValue(
														code,
														ICD_10_CODES.codes.sti
													)}
												</li>
											)
										)}
									</ul>
								) : null}
							</DescriptionItem>
						</Fragment>
					)}
					{!(hide && hide.includes('Test(s)')) && (
						<DescriptionItem
							term="Test(s)"
							termStyles={requiredStyles(
								data.requisition_form.order_tests
							)}
							details={
								data.requisition_form.order_tests.length
									? generateLabelForOrderTests(
											data.requisition_form.order_tests
									  )
									: null
							}
						/>
					)}
					{!(
						hide && hide.includes('Provider Signature Provided')
					) && (
						<Fragment>
							<div className="sm:col-span-1"></div>
							<DescriptionItem
								term="Provider Signature Provided"
								termStyles={requiredStyles(
									data.requisition_form
										.order_ordering_provider_signature_provided
								)}
								details={
									data.requisition_form
										.order_ordering_provider_signature_provided
								}
							/>
						</Fragment>
					)}
					{!(hide && hide.includes('Date Provided')) && (
						<DescriptionItem
							term="Date Provided"
							termStyles={requiredStyles(
								data.requisition_form
									.order_ordering_provider_date
							)}
							details={formatDate(
								data.requisition_form
									.order_ordering_provider_date
							)}
						/>
					)}
					{!(hide && hide.includes('Collection Date')) && (
						<Fragment>
							<div className="sm:col-span-1"></div>
							<DescriptionItem
								term="Collection Date"
								termStyles={requiredStyles(
									data.requisition_form.sample_collection_date
								)}
								details={formatDate(
									data.requisition_form.sample_collection_date
								)}
							/>
						</Fragment>
					)}
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection
			heading="Order and specimen information"
			headingAction={headingAction}
		>
			<DescriptionList>
				<DescriptionItem
					term="Assay"
					details={getLabelFromValue(
						data.sample.assay,
						ASSAYS['assays']
					)}
				/>
				<DescriptionItem
					term="Specimen Type"
					termStyles={requiredStyles(
						data.sample.sample_specimen_type
					)}
					details={getLabelFromValue(
						data.sample.sample_specimen_type,
						getSpecimenTypes()
					)}
				/>
				<div className="sm:col-span-1"></div>
				<DescriptionItem
					term="Test(s)"
					termStyles={requiredStyles(data.sample.order_tests)}
					details={generateLabelForOrderTests(
						data.sample.order_tests
					)}
				/>
				<DescriptionItem
					term="Collection Date"
					termStyles={requiredStyles(
						data.sample.sample_collection_date
					)}
					details={formatDate(data.sample.sample_collection_date)}
				/>
			</DescriptionList>
		</ContentSection>
	);
};

const PatientInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				headingAction={headingAction}
				heading="Provided patient information"
			>
				<DescriptionList>
					<DescriptionItem
						term="First Name"
						termStyles={requiredStyles(
							data.requisition_form.patient_first_name
						)}
						details={data.requisition_form.patient_first_name}
					/>
					<DescriptionItem
						term="Middle Name"
						details={data.requisition_form.patient_middle_name}
					/>
					<DescriptionItem
						term="Last Name"
						termStyles={requiredStyles(
							data.requisition_form.patient_last_name
						)}
						details={data.requisition_form.patient_last_name}
					/>
					<DescriptionItem
						term="Ethnicity"
						details={getLabelFromValue(
							data.requisition_form.patient_ethnicity,
							STI_V1_0.ethnicity_options
						)}
					/>
					<DescriptionItem
						term="Race"
						details={
							data.requisition_form.patient_race.length
								? data.requisition_form.patient_race
										.map((race: string) =>
											getLabelFromValue(
												race,
												STI_V1_0.race_options
											)
										)
										.join(', ')
								: null
						}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="Date of Birth"
						termStyles={requiredStyles(
							data.requisition_form.patient_date_of_birth
						)}
						details={formatDate(
							data.requisition_form.patient_date_of_birth
						)}
					/>
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.patient_sex
						)}
						term="Sex"
						details={
							data.requisition_form.patient_sex &&
							capitalize(data.requisition_form.patient_sex)
						}
					/>
					{data.requisition_form.sample_assay === 'sti' &&
					(data.requisition_form.patient_sex === 'female' ||
						!data.requisition_form.patient_sex) ? (
						<DescriptionItem
							term="Last menstrual date"
							termStyles={requiredStyles(
								data.requisition_form
									.patient_last_menstrual_date
							)}
							details={formatDate(
								data.requisition_form
									.patient_last_menstrual_date
							)}
						/>
					) : (
						<div className="sm:col-span-1"></div>
					)}
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.patient_phone_number
						)}
						term="Phone Number"
						details={data.requisition_form.patient_phone_number}
					/>
					<DescriptionItem
						term="Medical Record Number (MRN)"
						details={
							data.requisition_form.patient_medical_record_number
						}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.patient_address
						)}
						term="Address"
						details={data.requisition_form.patient_address}
					/>
					<DescriptionItem
						term="Address Line 2"
						details={data.requisition_form.patient_address2}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="City"
						termStyles={requiredStyles(
							data.requisition_form.patient_city
						)}
						details={data.requisition_form.patient_city}
					/>
					<DescriptionItem
						term="State"
						termStyles={requiredStyles(
							data.requisition_form.patient_state
						)}
						details={getLabelFromValue(
							data.requisition_form.patient_state,
							FORMS.states
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="ZIP Code"
						termStyles={requiredStyles(
							data.requisition_form.patient_zip_code
						)}
						details={data.requisition_form.patient_zip_code}
					/>
					<DescriptionItem
						term="Country"
						termStyles={requiredStyles(
							data.requisition_form.patient_country
						)}
						details={getLabelFromValue(
							data.requisition_form.patient_country,
							FORMS.countries
						)}
					/>
					<div className="sm:col-span-1"></div>
				</DescriptionList>
			</ContentSection>
		);

	return null;
};

const BillingInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<Fragment>
				<ContentSection heading="Billing status">
					<DescriptionList>
						<DescriptionItem
							term="Follow Up Required?"
							details={
								data.requisition_form.metadata
									.billing_verification.follow_up.required
							}
						/>
						{data.requisition_form.metadata.billing_verification
							.follow_up.required && (
							<Fragment>
								<DescriptionItem
									term="Follow up with?"
									details={capitalize(
										data.requisition_form.metadata
											.billing_verification.follow_up.with
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem term="Follow up again in?">
									{'Within '}
									{
										data.requisition_form.metadata
											.billing_verification.follow_up
											.in_days
									}
									{' Days'}
								</DescriptionItem>
								<DescriptionItem term="Days since last follow up?">
									{Math.round(
										(new Date().getTime() -
											new Date(
												data.requisition_form.metadata.billing_verification.follow_up.metadata.date?.$date
											).getTime()) /
											(1000 * 3600 * 24)
									)}
									{' Days'}
								</DescriptionItem>
							</Fragment>
						)}
					</DescriptionList>
				</ContentSection>

				<ContentSectionDivider />

				<ContentSection
					heading="Billing information"
					headingAction={headingAction}
				>
					<DescriptionList>
						<DescriptionItem
							term="Billing Method"
							termStyles={requiredStyles(
								data.requisition_form.billing_method
							)}
							details={getLabelFromValue(
								data.requisition_form.billing_method,
								STI_V1_0.billing_information_payer_options
							)}
						/>
						{data.requisition_form.billing_method === 'other' && (
							<DescriptionItem
								term="Billing Method (if other)"
								termStyles={
									data.requisition_form.billing_method ===
									'other'
										? requiredStyles(
												data.requisition_form
													.billing_method_other
										  )
										: ''
								}
								details={
									data.requisition_form.billing_method_other
								}
							/>
						)}
						<DescriptionItem
							term="Billing Verified?"
							termStyles={requiredStyles(
								data.requisition_form.billing_verified
							)}
							details={data.requisition_form.billing_verified}
						/>
					</DescriptionList>
				</ContentSection>

				{data.requisition_form.billing_method ===
					'patient_self_pay' && (
					<ContentSection heading="Payment verification">
						<DescriptionList>
							<DescriptionItem
								term="Patient Payment Received?"
								termStyles={requiredStyles(
									data.requisition_form
										.billing_patient_payment_received
								)}
								details={
									data.requisition_form
										.billing_patient_payment_received
								}
							/>
							<DescriptionItem
								term="Patient Payment Payee"
								termStyles={requiredStyles(
									data.requisition_form
										.billing_patient_payment_payee
								)}
								details={getLabelFromValue(
									data.requisition_form
										.billing_patient_payment_payee,
									REQUISITION_FORM['shared']
										.patient_payment_payee_options
								)}
							/>
						</DescriptionList>
					</ContentSection>
				)}

				{data.requisition_form.billing_method === 'client' && (
					<Fragment>
						<ContentSection heading="Client information">
							<DescriptionList>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_client_name
									)}
									details={
										data.requisition_form
											.billing_client_client_name
									}
								/>
								<DescriptionItem
									term="Institution"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_client_institution
									)}
									details={
										data.requisition_form
											.billing_client_client_institution
									}
								/>
							</DescriptionList>
						</ContentSection>
						<ContentSection heading="Client's billing contact information">
							<DescriptionList>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_name
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_name
									}
								/>
								<DescriptionItem
									term="Institution"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_institution
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_institution
									}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address"
									customColSpan="sm:col-span-2"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_address
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_address
									}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address Line 2"
									customColSpan="sm:col-span-2"
									details={
										data.requisition_form
											.billing_client_billing_contact_address2
									}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="City"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_city
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_city
									}
								/>
								<DescriptionItem
									term="State"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_state
									)}
									details={getLabelFromValue(
										data.requisition_form
											.billing_client_billing_contact_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_zip_code
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_zip_code
									}
								/>
								<DescriptionItem
									term="Country"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_country
									)}
									details={getLabelFromValue(
										data.requisition_form
											.billing_client_billing_contact_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Phone Number"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_phone_number
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_phone_number
									}
								/>
								<DescriptionItem
									term="Fax Number"
									details={
										data.requisition_form
											.billing_client_billing_contact_fax_number
									}
								/>
								<DescriptionItem
									term="Email Address"
									details={
										data.requisition_form
											.billing_client_billing_contact_email_address
									}
								/>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}

				{['insurance', 'medicare', 'medi-cal'].includes(
					data.requisition_form.billing_method
				) && (
					<Fragment>
						{data.requisition_form.billing_method ===
							'insurance' && (
							<ContentSection heading="Primary insurance company information">
								<DescriptionList>
									<DescriptionItem
										term="Insurance Type"
										details={getLabelFromValue(
											data.requisition_form
												.billing_primary_insurance_insurance_type,
											REQUISITION_FORM['shared']
												.billing_insurance_types
										)}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="Name"
										termStyles={requiredStyles(
											data.requisition_form
												.billing_primary_insurance_company_name
										)}
										details={
											data.requisition_form
												.billing_primary_insurance_company_name
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="Address"
										details={
											data.requisition_form
												.billing_primary_insurance_company_address
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="City"
										details={
											data.requisition_form
												.billing_primary_insurance_company_city
										}
									/>
									<DescriptionItem
										term="State"
										details={getLabelFromValue(
											data.requisition_form
												.billing_primary_insurance_company_state,
											FORMS.states
										)}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="ZIP Code"
										details={
											data.requisition_form
												.billing_primary_insurance_company_zip_code
										}
									/>
									<DescriptionItem
										term="Country"
										details={getLabelFromValue(
											data.requisition_form
												.billing_primary_insurance_company_country,
											FORMS.countries
										)}
									/>
									<div className="sm:col-span-1"></div>
								</DescriptionList>
							</ContentSection>
						)}

						<ContentSection heading="Primary insurance subscriber Information">
							<DescriptionList>
								<DescriptionItem
									term="First Name"
									details={
										data.requisition_form
											.billing_primary_insurance_subscriber_first_name
									}
								/>
								<DescriptionItem
									term="Last Name"
									details={
										data.requisition_form
											.billing_primary_insurance_subscriber_last_name
									}
								/>
								<DescriptionItem
									term="Date of Birth"
									details={formatDate(
										data.requisition_form
											.billing_primary_insurance_subscriber_date_of_birth
									)}
								/>
								<DescriptionItem
									term="Phone Number"
									details={
										data.requisition_form
											.billing_primary_insurance_subscriber_phone_number
									}
								/>
								<DescriptionItem
									term="Patient Relationship to Subscriber"
									details={
										data.requisition_form
											.billing_primary_insurance_patient_relationship_to_subscriber &&
										capitalize(
											data.requisition_form
												.billing_primary_insurance_patient_relationship_to_subscriber
										)
									}
								/>
								{data.requisition_form
									.billing_primary_insurance_patient_relationship_to_subscriber ===
								'other' ? (
									<DescriptionItem
										term="Patient Relationship to Subscriber (if other)"
										termStyles={requiredStyles(
											data.requisition_form
												.billing_primary_insurance_patient_relationship_to_subscriber_other
										)}
										details={
											data.requisition_form
												.billing_primary_insurance_patient_relationship_to_subscriber_other
										}
									/>
								) : (
									<div className="sm:col-span-1"></div>
								)}
								<DescriptionItem
									term="Policy ID #"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_primary_insurance_subscriber_policy_id_number
									)}
									details={
										data.requisition_form
											.billing_primary_insurance_subscriber_policy_id_number
									}
								/>
								<DescriptionItem
									term="Group #"
									details={
										data.requisition_form
											.billing_primary_insurance_subscriber_group_number
									}
								/>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}

				{data.requisition_form.billing_has_secondary_insurance && (
					<Fragment>
						<ContentSection heading="Secondary insurance company information">
							<DescriptionList>
								<DescriptionItem
									term="Insurance Type"
									details={getLabelFromValue(
										data.requisition_form
											.billing_secondary_insurance_insurance_type,
										REQUISITION_FORM['shared']
											.billing_insurance_types
									)}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_secondary_insurance_company_name
									)}
									details={
										data.requisition_form
											.billing_secondary_insurance_company_name
									}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="Address"
									details={
										data.requisition_form
											.billing_secondary_insurance_company_address
									}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="City"
									details={
										data.requisition_form
											.billing_secondary_insurance_company_city
									}
								/>
								<DescriptionItem
									term="State"
									details={getLabelFromValue(
										data.requisition_form
											.billing_secondary_insurance_company_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									details={
										data.requisition_form
											.billing_secondary_insurance_company_zip_code
									}
								/>
								<DescriptionItem
									term="Country"
									details={getLabelFromValue(
										data.requisition_form
											.billing_secondary_insurance_company_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
							</DescriptionList>
						</ContentSection>

						<ContentSection heading="Secondary insurance subscriber information">
							<DescriptionList>
								<DescriptionItem
									term="First Name"
									details={
										data.requisition_form
											.billing_secondary_insurance_subscriber_first_name
									}
								/>
								<DescriptionItem
									term="Last Name"
									details={
										data.requisition_form
											.billing_secondary_insurance_subscriber_last_name
									}
								/>
								<DescriptionItem
									term="Date of Birth"
									details={formatDate(
										data.requisition_form
											.billing_secondary_insurance_subscriber_date_of_birth
									)}
								/>
								<DescriptionItem
									term="Phone Number"
									details={
										data.requisition_form
											.billing_secondary_insurance_subscriber_phone_number
									}
								/>
								<DescriptionItem
									term="Patient Relationship to Subscriber"
									details={
										data.requisition_form
											.billing_secondary_insurance_patient_relationship_to_subscriber &&
										capitalize(
											data.requisition_form
												.billing_secondary_insurance_patient_relationship_to_subscriber
										)
									}
								/>
								{data.requisition_form
									.billing_secondary_insurance_patient_relationship_to_subscriber ===
								'other' ? (
									<DescriptionItem
										term="Patient Relationship to Subscriber (if other)"
										termStyles={requiredStyles(
											data.requisition_form
												.billing_secondary_insurance_patient_relationship_to_subscriber_other
										)}
										details={
											data.requisition_form
												.billing_secondary_insurance_patient_relationship_to_subscriber_other
										}
									/>
								) : (
									<div className="sm:col-span-1"></div>
								)}
								<DescriptionItem
									term="Policy ID #"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_secondary_insurance_subscriber_policy_id_number
									)}
									details={
										data.requisition_form
											.billing_secondary_insurance_subscriber_policy_id_number
									}
								/>
								<DescriptionItem
									term="Group #"
									details={
										data.requisition_form
											.billing_secondary_insurance_subscriber_group_number
									}
								/>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}
			</Fragment>
		);

	return null;
};

const MiscAndAttachments = ({
	data,
	entity,
	headingAction,
}: MiscAndAttachmentsFieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				headingAction={headingAction}
				heading="Authorization and Attachments"
			>
				<DescriptionList>
					<DescriptionItem
						term="Patient Signature Provided"
						termStyles={requiredStyles(
							data.requisition_form
								.patient_authorization_signature_provided
						)}
						details={
							data.requisition_form
								.patient_authorization_signature_provided
						}
					/>
					<DescriptionItem
						term="Date Provided"
						termStyles={requiredStyles(
							data.requisition_form.patient_authorization_date
						)}
						details={formatDate(
							data.requisition_form.patient_authorization_date
						)}
					/>
					<div className="sm:col-span-1"></div>
					<Attachments
						data={data}
						entity={entity}
						showRequiredStyles={true}
					/>
				</DescriptionList>
			</ContentSection>
		);

	return null;
};

export const V1_0 = {
	BillingInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
	OrderAndSpecimenInformation,
	MiscAndAttachments,
};

export default V1_0;
